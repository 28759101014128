import type {
  AttachBuyerToPaymentTunnelResult,
  BookingSlot,
  BuyerInfos,
  CreatePaymentTransactionResult
} from '~/@types/graphql-zeppelin'
import { documents } from './documents'
import { requestZeppelin } from './helpers/requestZeppelin'

export function createPaymentTransaction(
  accessToken: string,
  slotId?: BookingSlot['id']
) {
  return requestZeppelin<CreatePaymentTransactionResult>(
    accessToken,
    documents.mutations.createPaymentTransactionDocument,
    {
      bookingSlotId: slotId
    }
  )
}

export function confirmStripePayment(
  accessToken: string,
  clientSecret: string,
  body: BuyerInfos
) {
  return requestZeppelin<AttachBuyerToPaymentTunnelResult>(
    accessToken,
    documents.mutations.attachInfosToPaymentTunnelDocument,
    {
      clientSecret,
      buyerInfos: body
    }
  )
}
