import { GraphQLClient } from 'graphql-request'
import { API_ORIGIN_ZEPPELIN } from '~/constants/env'
import {
  getOperationName,
  processRequestData,
  SuccessResult,
  trackErrorInSentry
} from './utils'

export async function requestZeppelin<T extends object, R = SuccessResult<T>>(
  accessToken: string,
  document: string,
  variables?: Record<string, unknown>
): Promise<R> {
  const client = new GraphQLClient(
    `${API_ORIGIN_ZEPPELIN}?operationName=${getOperationName(document)}`
  )
  client.setHeader('Authorization', `Bearer ${accessToken}`)

  try {
    const data = await client.request<T>(document, variables)

    return await processRequestData<T, R>(data)
  } catch (error: unknown) {
    trackErrorInSentry(error)

    throw error
  }
}
